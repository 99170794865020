import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

import gql from "graphql-tag";
export var SETTING_RATE = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query {\n    settingRate {\n      rateAlipay\n      rateBuy\n      rateTaobao\n    }\n  }\n"])));
export var SETTING_ADDRESS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query {\n    settingAddress {\n      sender_name\n      sender_phone\n      sender_address\n      sender_province\n      sender_amphoe\n      sender_district\n      sender_postcode\n    }\n  }\n"])));
export var SETTING_SERVICE_DROPSHIP = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query {\n    serivcePriceDropship {\n      price\n    }\n  }\n"])));
export var UPDATE_SETTING_SERVICE_DROPSHIP = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: SettingPriceInput) {\n    updateServicePriceDropshipSetting(input: $input) {\n      isSuccess\n      code\n      message\n    }\n  }\n"])));
export var SETTING_NOTI = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query {\n    setting_notification_bar {\n      isSuccess\n      data\n    }\n  }\n"])));
export var SETTING_ADDRESS_CHINA = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query {\n    settingAddressChina {\n      isSuccess\n      message\n      data {\n        china_land\n        china_sea\n        china_address\n        china_phone\n        china_guarantee\n      }\n    }\n  }\n"])));
export var UPDATE_SETTING_RATE = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation($input: SettingRateInput) {\n    updateRate(input: $input) {\n      isSuccess\n      code\n      message\n    }\n  }\n"])));
export var UPDATE_SETTING_ADDRESS = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation($input: SettingAddressInput) {\n    updateAddressSetting(input: $input) {\n      isSuccess\n      code\n      message\n    }\n  }\n"])));
export var UPDATE_SETTING_ADDRESS_CHINA = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation($input: SettingAddressChinaInput) {\n    updateAddressChinaSetting(input: $input) {\n      isSuccess\n      code\n      message\n    }\n  }\n"])));
export var UPDATE_SETTING_NOTI = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation($input: SettingNotificationInput) {\n    updateNotificationSetting(input: $input) {\n      isSuccess\n      code\n      message\n    }\n  }\n"])));
export var MINIMUM_PAYSHIPPING = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  query {\n    minimumPayshipping {\n      minimum\n      price\n    }\n  }\n"])));
export var UPDATE_MINIMUM_PAYSHIPPING = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation($minimum: Float, $price: Float) {\n    updateMinimumPayshipping(minimum: $minimum, price: $price) {\n      isSuccess\n      code\n      message\n    }\n  }\n"])));